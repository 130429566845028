import React, { useEffect, useState } from "react";
import './App.css';
import { ReactComponent as WorkSvg } from './assets/work.svg';
import { ReactComponent as BreakSvg } from './assets/break.svg';

function App() {
  // 休憩時間（ミリ秒）
  const breakTime = 30000;
  // 集中時間（ミリ秒）
  const workTime = 150000;
  // 最大の横の長さ
  const maxWidth = 430;

  // ミリ秒を変換
  const calculateTimeLeft = (t) => {
      let timeLeft = {}
      timeLeft = {
        // 分
        minutes: Math.floor(t / 100  / 60).toString().padStart(2, '0'),
        // 秒
        seconds: Math.floor(t / 100 % 60).toString().padStart(2, '0'),
        // ミリ秒
        miliseconds: Math.floor(t % 100).toString().padStart(2, '0')
      }
  
    return timeLeft;
  }
　
  // ミリ秒でカウントダウン
  const [time, setTime] = React.useState(500);
  // 休憩時間かどうか
  const [isBreak, setIsBreak] = React.useState(true);
  // ポモドーロカウント
  const [pomodoroCnt, setPomodoroCnt] = React.useState(0);
  // 表示する時間（分：秒：ミリ秒）
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(time));
  // ボックスの横の長さ
  const [boxWidth, setBoxWidth] = useState({width:'420px'});

  useEffect(() => {
    const timer = setTimeout(() => {
      // ミリ秒のカウントダウン
      setTime(t => t - 1)
      // ミリ秒を変換
      setTimeLeft(calculateTimeLeft(time))
      
      // 休憩時間ではなく、タイマー終了した場合
      if (time < 0 && !isBreak){
        setIsBreak(true);
        setTime(breakTime);
        setTimeLeft(calculateTimeLeft(time))
      // 休憩時間で、タイマーが終了した場合
      }else if(time < 0 && isBreak){
        setIsBreak(false);
        setTime(workTime);
        setTimeLeft(calculateTimeLeft(time))
        if (pomodoroCnt < 4){
          setPomodoroCnt(c => c + 1);
        }else{
          setPomodoroCnt(1);
        }
      }
      
      // ボックスの横の長さを計算
      let width;
      if (!isBreak){
        width =  Math.floor((maxWidth * (time/100)) / (workTime/100));
      }else{
        width =  Math.floor((maxWidth * (time/100)) / (breakTime/100));
      }
      
      // Styleを動的に変更
      setBoxWidth({
        width:width+'px'
      })


    }, 10);
    return () => clearTimeout(timer);

  });

  return (
    <div className="App">
      {!isBreak ? (

          <div className="svg_wrapper work_svg_wrapper">
            <div className="view_svg">
              <WorkSvg />
            </div>

            <div className="text">
              作業時間
            </div>
          </div>
        ) : (

        <div className="svg_wrapper break_svg_wrapper">
          <div className="view_svg">
            <BreakSvg />
          </div>
          
          <div className="text">
            休憩時間
          </div>
        </div>
        )}




      {!isBreak ? (
        <span class="timer noBreak">{timeLeft.minutes} : {timeLeft.seconds} : {timeLeft.miliseconds}</span>
      ) : (
        <span class="timer isBreak">{timeLeft.minutes} : {timeLeft.seconds} : {timeLeft.miliseconds}</span>
      )}

      <div class="boxBack" style={{width:"430px"}}></div>

      {!isBreak ? (
        <div class="box noBreakBox" style={boxWidth}></div>
      ) : (
        <div class="box isBreakBox" style={boxWidth}></div>
      )}  

      <div class="circle_wrapper">
        <span class="circle circleSet">1</span>

        {pomodoroCnt > 1 ? (
            <span class="circle circleSet">2</span>
          ) :(
            <span class="circle">2</span>
          )
        }

        {pomodoroCnt > 2 ? (
            <span class="circle circleSet">3</span>
          ) :(
            <span class="circle">3</span>
          )
        }

        {pomodoroCnt > 3 ? (
            <span class="circle circleSet">4</span>
          ) :(
            <span class="circle">4</span>
          )
        }
      </div>


    </div>
  );
}

export default App;
